import { relative } from 'node:path'
import React from 'react'
import styled from 'styled-components'
import bgImg from '../images/svg/bg_path.svg'
import healthyGirl from '../images/svg/healthy_girl.svg'
import { devices } from '../styles/devices'
import ButtonCTA from './ButtonCTA'

export default function HowDoesItWork() {
    const SectionWrapper = styled.section`
        width: 100%;
        height: 100%;
        // max-height: 1700px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    `

    const Underline = styled.hr`
        width: 120px;
        height 0.5rem;
        background-color: #2ec4b6;
        border: 1px transparent;
  `

    const Column = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
    `

    const HeadText = styled.h1`
        font-size: 3rem;
        text-align: center;
        font-weight: 300;
        color: #000;
        margin-bottom: 0rem;

        @media (max-width: ${devices.sm}) {
            font-size: 2.5rem;
            margin: 0 1rem;
        }
    `

    const DetailText = styled.p`
        width: 100%;
        font-size: 1.2rem;
        font-weight: 300;
        color: #000;
    `

    const Container = styled.div`
        width: 80%;
        max-width: 920px;
        margin: 1rem 0rem;
    `

    const Row = styled.div`
        width: 100%;
        display: flex;
        position: relative;
    `

    const ImageBg = styled.img`
        width: 100%;
        position: absolute;
        z-index: 0;
        bottom: 0;
        object-fit: cover;
        left: 0;
    `

    return (
        <SectionWrapper>
            <Column>
                <HeadText>HOW DOES IT WORK?</HeadText>
                <Underline />
            </Column>
            <Container style={{ position: 'relative', zIndex: 1 }}>
                <Column style={{ alignItems: 'center' }}>
                    <DetailText>
                        If you want to decrease your intake of junk food it’s important to determine what is causing you
                        to eat it. What happened just before you made the decision to eat it or purchase it? Sometimes
                        it’s a reaction to a picture or video you saw. Sometimes these cravings seem to just appear
                        randomly out of thin air. But these cravings don’t have to be so strong. By playing these simple
                        games you can actually train your brain to stop having so many of these cravings. On top of
                        that, you can train yourself to no longer be so overwhelmed by seeing pictures and videos of
                        those foods you crave so much.
                    </DetailText>
                    <div style={{ marginTop: '1rem' }}>
                        <ButtonCTA text="Learn More" color="#2ec4b6" width="8.5rem" />
                    </div>
                </Column>
            </Container>
            <Row style={{ marginTop: '0rem', position: 'relative', paddingBottom: '2rem' }}>
                <Container
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '100vw',
                        width: '100%',
                    }}
                >
                    <img
                        src={healthyGirl}
                        alt="healthy"
                        style={{ position: 'relative', zIndex: 2, maxWidth: '650px', width: '50%', bottom: '-1.5rem' }}
                    />
                </Container>
                <ImageBg src={bgImg} />
            </Row>
            <Container>
                <Row style={{ justifyContent: 'center' }}>
                    <DetailText style={{ color: '#2ec4b6', textAlign: 'center', maxWidth: '20rem' }}>
                        We know you care about your health. We do too.
                    </DetailText>
                </Row>
            </Container>
        </SectionWrapper>
    )
}
