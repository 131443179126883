import React from 'react'
import styled from 'styled-components'
import comingsoonBg from "../images/png/comingsoon-bg.png"
import phone from '../images/png/phone.png'
import { devices } from '../styles/devices'
import SubscribeModal from '../components/SubscribeModal'

const Row = styled.div`
    display: flex;
    @media (max-width: ${devices.md}) {
        display: block;
    }
`;

const Col = styled.div`
    flex: ${(props) => props.size};
`;

const ComingSoonbgstyle = styled.div`
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    height: 530px;
    width: 500px;
    transform:translateX(-150px);
    @media (max-width: ${devices.md}) {
        transform:none;
     }
     @media (max-width: ${devices.sm}) {
        width: auto;
     }
`;

const ComingSoonSection = styled.div`
    padding-top:100px;
    @media (max-width: ${devices.md}) {
        padding-top:0px
    }
`

const BoxComingSoon = styled.div`
    width: 300px;
    height: 200px;
    background: #FFF8E8;
    box-shadow: rgb(0 0 0 / 10%) 0 4px 10px;
    padding: 40px;
    text-align:center;
    margin-top: 100px;
    @media (max-width: ${devices.md}) {
       margin: 40px auto;
       padding: 20px
    }
`;

const ButtonCenter = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
`

const TitleHeadComingSoon = styled.h1 `
    font-size:2rem;
    font-weight: 300;
    @media (max-width: ${devices.lg}) {
      font-size:30px
    }
`
const SubTitleHeadComingSoon = styled.h1 `
    font-size:1.5rem;
    font-weight: 300;
    @media (max-width: ${devices.lg}) {
      font-size:1rem
    }
`
const ComingSoonbgCenter = styled.div `
    @media (max-width: ${devices.lg}) {
     text-align:center;
    }
`

export default function ComingSoon() {
        return (
            <ComingSoonSection>
                 <Row>
                    <Col size={1}>
                        <ComingSoonbgstyle style={{ backgroundImage: `url(${comingsoonBg})` }}></ComingSoonbgstyle> 
                        <ComingSoonbgCenter>
                         <img src={phone} style={{width: '230px',transform:'translateY(-500px)',marginBottom:'-400px'}}/>
                        </ComingSoonbgCenter>
                    </Col>
                    <Col size={1}>
                        <BoxComingSoon>
                            <TitleHeadComingSoon> Coming Soon </TitleHeadComingSoon>
                            <SubTitleHeadComingSoon>21/10/21</SubTitleHeadComingSoon>
                            <ButtonCenter>
                                 <SubscribeModal titleModal="Please Email Me" btnWidth={"auto"} btnColor="#2ec4b6"  btnTitleText="Contact Us"> </SubscribeModal>
                            </ButtonCenter>
                        </BoxComingSoon>
                    </Col>
                </Row>
            </ComingSoonSection>
        )
}

