import React from 'react'
import Layout from '../components/Layout'
import BannerHero from '../components/BannerHero'
import ComingSoon from '../components/ComingSoon'
import StartEatingHealthierToday from '../components/StartEatingHealthierToday'
import '../styles/global.scss'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { devices } from '../styles/devices'
import HowDoesItWork from '../components/HowDoesItWork'
import WhyHealthyLife from '../components/WhyHealthyLife'
import Footer from '../components/Footer'

export default function index() {
    return (
        <div className="container">
            <Helmet>
                <title>Healthy Life</title>
            </Helmet>
            <Layout>
                <BannerHero/>
                <HowDoesItWork/>
                <WhyHealthyLife/>
                <ComingSoon/>
                <StartEatingHealthierToday/>
                <Footer/>
            </Layout>
        </div>
    )
}
