import React from 'react'
import styled from 'styled-components'
import ButtonCTA from './ButtonCTA'
// import footerBg from "../images/png/footer-bg.png"

import { devices } from '../styles/devices'


const StartEatingHealthierTodayLanding = styled.div`
    display:block;
    background-repeat:no-repeat;
    background-position: top center;
    background-size: cover;
    height: 400px;
    width: 100%;
    @media (max-width: ${devices.lg}) {
        height: auto;
    }
`;

const TextContent = styled.div `
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-top: 50px;
    @media (max-width: ${devices.lg}) {
      padding:20px
    }
    @media (max-width: ${devices.sm}) {
        width: auto;
    }
`
const StartEatingHealthierTodayLandingTitleHead = styled.h1 `
    font-size: 2.5rem;
    font-weight: 300;
    @media (max-width: ${devices.lg}) {
      font-size:2rem
    }
`
const TextSubContent = styled.div `
    width: 50%;
    margin: 0 auto;
    text-align: center;
    font-size:1.5rem;
    font-weight: 300;
    @media (max-width: ${devices.lg}) {
      font-size:1rem;
    }
    @media (max-width: ${devices.sm}) {
        width: 80%;
    }
`

const ButtonCenter = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
`

 export default function StartEatingHealthierToday() {
        return (
            <StartEatingHealthierTodayLanding style={{backgroundColor: '#f7f3e9'}}>
              <TextContent>
                  <StartEatingHealthierTodayLandingTitleHead>Start Eating Healthier Today</StartEatingHealthierTodayLandingTitleHead>
                  <TextSubContent>
                  <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum'</p>
                  </TextSubContent>
                  <ButtonCenter>
                     <ButtonCTA text="Click here" color="#2ec4b6" width="8.5rem" border="none"/>
                  </ButtonCenter>
              </TextContent>
            </StartEatingHealthierTodayLanding>
        )
}

