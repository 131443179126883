import React from 'react'
import styled from 'styled-components'
import BannerHeroBgimg from "../images/png/bannerhero-bg.png"
import bannerHeroBgRight from '../images/png/bannerhero-bg-right.png'
import phoneBg from '../images/png/iPhonebg-while.png'
import screenVideo from '../videos/screen-homepage.mp4'
import { devices } from '../styles/devices'
import SubscribeModal from '../components/SubscribeModal'

 const Grid =  styled.div`

`;

 const Row =  styled.div`
    display: flex;
    @media (max-width: ${devices.sm}) {
        display: block;
    }
`;

 const Col =  styled.div`
    flex: ${(props) => props.size};   
    position: relative;
`;

const BannerHerobg = styled.div`
    display:block;
    background-repeat:no-repeat;
    background-position: top center;
`;

const HeroBannerLeftSide = styled.div`
    width: 50%;
    position: relative;
    padding-top: 100px;
    float: right;


    @media (max-width: 1500px) {
        width: 60%;
        float: right;
        padding: 40px;
    }
    @media (max-width: ${devices.xxl}) {
        width: 70%;
        float: right;
        padding: 40px;
    } 
    @media (max-width: ${devices.xl}) {
        width: 75%;
        float: right;
        padding: 40px;
    } 
    @media (max-width: ${devices.md}) {
        width: auto;
        float: inherit;
        padding: 40px;
    }    
`;

const TitleHead = styled.span`
    font-size:3.5rem;
    font-weight: 200;
    @media (max-width: ${devices.lg}) {
        font-size:3rem;
    }
    @media (max-width: ${devices.sm}) {
        font-size:2.5rem;
    }
`
const Subtitle = styled.div`
    font-size:1.25rem;
    font-weight: 300;
    padding: 30px 0;
`
const BannerHeroBgRight = styled.div`
    background-repeat:no-repeat;
    height:540px;
    transform:translateY(250px);
    @media (max-width: ${devices.sm}) {
      transform:translateY(50px);
    }
`

const HeroBannerRightSide = styled.div`
    transform:translateX(190px);  
    @media (max-width: ${devices.xl}) {
        transform:translateX(100px);  
    }
    @media (max-width: ${devices.lg}) {
        padding: 0px 0px;
        transform: none;
    }
    @media (max-width: ${devices.sm}) {
        padding: 20px 20px;
    }
`

const BannerHeroPhone = styled.div`
    background-repeat:no-repeat;
    //  height: 690px;
    transform:translateY(-560px);
    margin-bottom: -400px;
    background-size: contain;

    @media (max-width: ${devices.md}) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: -300px;
    }
    @media (max-width: ${devices.sm}) {
        margin-bottom: -400px;
    }

`
const BannerHeroPhoneImg = styled.img`
    width:50%;
    position: relative;
    z-index: 1;

    @media (max-width: ${devices.md}) {
        width:80%;
    }
`

const BannerHeroPhoneVideo = styled.video`
    width:40%;
    position: absolute;
    right: 0;
    left: 5%;
    top: 9%;
    z-index: 2;
    @media (max-width: ${devices.md}) {
        width: 60%;
        left: 20%;
        top: 11%;
    }
`
export default function BannerHero() {
        return (
            <BannerHerobg style={{backgroundImage:`url(${BannerHeroBgimg})`}}>
                   <Row >
                    <Col size={1}>
                        <HeroBannerLeftSide> 
                            <TitleHead>Eating Healthier Has Never Been This Easy</TitleHead>
                             <Subtitle>
                              Improve your dietary habits by playing games designed on groundbreaking 
                               research for only 10 minutes a day. Make better dietary Choices.
                             </Subtitle>
                             <div>
                                 <SubscribeModal titleModal="Please Email Me" btnWidth="30%" btnColor="#2ec4b6" btnTitleText="Subscribe to Us" > </SubscribeModal>
                             </div>
                        </HeroBannerLeftSide>
                    </Col>
                    <Col size={1}>
                        <HeroBannerRightSide>
                        <BannerHeroBgRight style={{backgroundImage:`url(${bannerHeroBgRight})`}}>
                         </BannerHeroBgRight>
                         {/* <BannerHeroPhone style={{backgroundImage:`url(${phone})`}}> 
                         </BannerHeroPhone> */}
                        <BannerHeroPhone> 
                            <BannerHeroPhoneImg src={phoneBg}/>
                            <BannerHeroPhoneVideo  autoPlay loop muted>
                            <source src={screenVideo} type="video/mp4"/>
                            </BannerHeroPhoneVideo> 
                        </BannerHeroPhone>
                                        
                        </HeroBannerRightSide>
                    </Col>
                </Row>
            </BannerHerobg>
        )
}