import React from 'react'
import styled from 'styled-components'
import funIcon from '../images/svg/fun.svg'
import provenIcon from '../images/svg/proven.svg'
import personalIcon from '../images/svg/personal.svg'
import { devices } from '../styles/devices'

export default function WhyHealthyLife() {
    const cardList = [
        {
            icon: funIcon,
            title: (
                <div>
                    Fun <br /> Daily Exercise
                </div>
            ),
            detail: 'Train your brain to stop craving junk food playing simple games',
        },
        {
            icon: provenIcon,
            title: (
                <div>
                    Proven Scientific
                    <br />
                    Research
                </div>
            ),
            detail:
                'Healthy Life games are based on groundbreaking research that can help you shake off those bad habits.',
        },
        {
            icon: personalIcon,
            title: `Personalized goals`,
            detail: 'No matter what your goal is, Healthy Life will help you reach you those goals.',
        },
    ]

    const SectionWrapper = styled.section`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    `

    const Underline = styled.hr`
    width: 120px;
    height 0.5rem;
    background-color: #2ec4b6;
    border: 1px transparent;
  `

    const Column = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
    `

    const HeadText = styled.h1`
        font-size: 3rem;
        text-align: center;
        font-weight: 300;
        color: #000;
        margin-bottom: 0rem;

        @media (max-width: ${devices.sm}) {
            font-size: 2.5rem;
            margin: 0 1rem;
        }
    `
    const Container = styled.div`
        width: 80%;
        max-width: 920px;
        margin: 1rem 0rem;
        position: relative;
    `

    const Row = styled.div`
        width: 100%;
        display: flex;
    `

    const RectangleBg = styled.div`
        position: absolute;
        top: -20%;
        left: 0;
        width: 15rem;
        height: 20rem;
        background: rgb(2, 0, 36);
        background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(238, 159, 87, 1) 0%, rgba(238, 159, 87, 0) 100%);
        border-radius: 4px;
        transform: rotate(-45deg);
        z-index: 0;

        @media (max-width: ${devices.lg}) {
            display: none;
        }
    `

    const CardWrapper = styled.div`
        position: relative;
        z-index: 1;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 2rem 0rem;

        @media (max-width: ${devices.lg}) {
            flex-direction: column;
            margin: 2rem 0rem;
        }
    `

    const Card = styled.div`
        flex: 0 0 30%;
        max-width: 300px;
        height: 300px;
        padding-bottom: 1rem;
        background: #FFF8E8;
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: ${devices.lg}) {
            flex: 0 0 100%;
            max-width: 900px;
            flex-direction: column;
            margin: 2rem 2rem;
        }
    `

    const CardTitle = styled.h4`
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        color: #2ec4b6;
        margin: 1rem 0rem;
        margin-bottom: 0rem;
    `
    const CardDetail = styled.p`
        max-width: 400px;
        text-align: center;
        font-size: 0.75rem;
        font-weight: 300;
        width: 70%;
        max-height: 300px;
        margin-top: 0rem;
        color: #000;
        margin: 0;
        padding: 1rem;
    `

    return (
        <SectionWrapper>
            <Column>
                <HeadText>WHY Healthy Life?</HeadText>
                <Underline />
            </Column>
            <Container>
                <CardWrapper>
                    {cardList.map((item, index) => {
                        return (
                            <Card key={index}>
                                <img src={item.icon} alt="icon" style={{ padding: '1rem' }} />
                                <CardTitle>{item.title}</CardTitle>
                                <CardDetail>{item.detail}</CardDetail>
                            </Card>
                        )
                    })}
                </CardWrapper>
                <RectangleBg />
            </Container>
        </SectionWrapper>
    )
}
